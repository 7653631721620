import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
    icon: 'warning' // success
};

Vue.use(VueSweetalert2, options);

export function sweetWarning(title, message) {

    Vue.swal({
        title: title,
        html: message,
        icon: "warning",
    });
}

export function sweetError(title, message) {

    Vue.swal({
        title: title,
        html: message,
        icon: "error",
    });
}
